import React from 'react';
import MenuTree from "../MenuTree"
import Navbar from 'react-bootstrap/Navbar'
// import vectorIconsLogo from "../../assets/images/logo.svg"
import Logo from "../Logo";

class MainMenu extends MenuTree {

    tolerance = 10;
    collapseDelay  = 500;

    constructor(props) {
        super(props)

        this.state = {
            subMenuClass : 'mega-sub-menu',
            menuId       : 'mega-menu-main_navigation',
            scrolled     : false,
            expanded     : false,
            background   : 'light',
            variant      : 'light'
        }

        this.navRef = React.createRef();

        this.handleScroll         = this.handleScroll.bind(this)
        this.handleTogglerClick   = this.handleTogglerClick.bind(this)
        this.updateClassNames     = this.updateClassNames.bind(this)
        this.isExpandedOrScrolled = this.isExpandedOrScrolled.bind(this)
        this.getScrolled          = this.getScrolled.bind(this);
    }

    componentDidMount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.addEventListener('scroll', this.handleScroll);

        this.updateClassNames();

        this.setState({
            scrolled   : this.getScrolled()
        })
    }

    getScrolled() {
        if (window === undefined) return false;
        return window.pageYOffset > this.tolerance;
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e) {
        this.setState({
            scrolled : window.pageYOffset > this.tolerance
        });
    }

    handleTogglerClick(e) {
        window.setTimeout(() => {
            this.setState({
                expanded : ! this.state.expanded
            })
            this.updateClassNames();
        }, this.state.expanded ? this.collapseDelay : 0 )
    }

    updateClassNames() {
        this.navRef.current.classList.remove('expanded');
        this.navRef.current.classList.remove('collapsed');
        this.navRef.current.classList.remove('scrolled');
        this.navRef.current.classList.remove('not-scrolled');
        this.navRef.current.classList.add(this.state.expanded ? 'expanded' : 'collapsed');
        this.navRef.current.classList.add(this.state.scrolled ? 'scrolled' : 'not-scrolled');
    }

    isExpanded() {
        return this.state.expanded
    }

    isScrolled() {
        return this.state.scrolled;
    }

    isExpandedOrScrolled() {
        return this.isScrolled() || this.isExpanded()
    }

                                                                                                                                                                                                        isExpandedAndScrolled() {
        return this.isScrolled() && this.isExpanded()
    }

    render() {
        return (
            <Navbar
                fluid={'true'}
                collapseOnSelect
                expand={'lg'}
                fixed={'top'}
                id={'top-nav'}
                ref={this.navRef}
                variant={'dark'}
            >
                <Navbar.Brand href="/">
                    <Logo/>
                    {/*<img*/}
                    {/*    alt="VectorIcons.net Logo"*/}
                    {/*    src={vectorIconsLogo}*/}
                    {/*    width={this.isExpanded() ? 200 : 200}*/}
                    {/*    className="d-inline-block align-top"*/}
                    {/*/>*/}
                </Navbar.Brand>
                <Navbar.Toggle onClick={this.handleTogglerClick} aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className={'justify-content-end'}>
                    {super.render()}
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default MainMenu
