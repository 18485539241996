// React & Gatsby
import React, {Component} from "react"
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { uuid } from 'uuidv4';

// Custom
import {PageLinks} from "../../constants";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faDollarSign } from '@fortawesome/free-solid-svg-icons'

// Data & Styles


class MenuTree extends Component {

    constructor(props) {
        super(props)
        this.state = {
            subMenuClass : 'sub-menu',
            menuId : 'main-menu'
        }

        this.handleDropDownToggle = this.handleDropDownToggle.bind(this)
        this.getDropDownNav       = this.getDropDownNav.bind(this)
        this.getMenuItem          = this.getMenuItem.bind(this)
    }

    componentDidMount() {
        this.node = this.currentRef.current;
    }

    handleDropDownToggle(show, guid, ref) {

        if (! show) {
            const filtered = [];
            ref.className.split(' ').map((c) => {
                if ( ['hide', 'show'].includes(c.trim()) ) return c;
                if (! filtered.includes(c.trim())) {
                    filtered.push(c);
                }
                return c;
            })
            filtered.push('hide');
            ref.className = filtered.join(' ');
        }
    }

    getDropDownNav(menuItem, i) {
         const guid = `dropdown-${uuid()}`;
         const ref = React.createRef();
         return (
             <NavDropdown
                 id={guid}
                 className={this.state.className}
                 onToggle={(show) => { this.handleDropDownToggle(show, guid, ref.current) } }
                 key={`nav-dropdown-${uuid()}`}
                 title={menuItem.text}
                 ref={ref}
             >
                 {menuItem.children.map((child, j) => {
                     return (
                         <NavDropdown.Item
                             key={`nav-item-${uuid()}`}
                             eventKey={`${i}.${j}`}
                             href={child.link}
                         >
                             {child.text}
                         </NavDropdown.Item>
                     )
                 })}
             </NavDropdown>
        )
    }

    getMenuItem(menuItem, i) {
        const lastClass = this.props.menuItems.length - 1 === i ? 'last' : '';
        if (! menuItem.children) {
            return (
                <Nav.Link key={`nav-link-${uuid()}`} className={lastClass} eventKey={`${i}`} href={menuItem.link}>
                    {menuItem.text}
                </Nav.Link>
            )
        }
        else {
            const dropDownNav = this.getDropDownNav(menuItem, i)
            if (lastClass) {
                dropDownNav.classList.add(lastClass);
            }
            return dropDownNav;
        }
    }

    onClick() {
        if (typeof window === 'object') {
            window.location.href = PageLinks.IconsPricing;
        }
    }

    render() {
        return (
            <Nav key={`nav-${uuid()}`}>
                {this.props.menuItems.map((menuItem, i) => {
                    return ( this.getMenuItem(menuItem, i) )
                })}
            </Nav>
        )
    }
}

export default MenuTree
