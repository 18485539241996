import React, {Component} from "react"
import SchemaPerson from "./SchemaPerson"
import SchemaProduct from "./SchemaProduct"
import SchemaAggregateOffer from "./SchemaAggregateOffer"

class Schema extends Component {
    render() {
        return (
            <React.Fragment>
                <SchemaPerson
                        givenName={'Scott'}
                        familyName={'Lewis'}
                        worksFor={'https://atomiclotus.net'}
                        jobTitle={'Software Engineer'}
                        gender={'http://schema.org/Male'}
                        sameAs={['https://twitter.com/iconifyit']}
                />
                <SchemaProduct
                        name={'Diversity Avatars Ultimate Pack'}
                        image={'https://diversityavatars.com/assets/images/3-faces.png'}
                        description={
                            '1,000 high-quality avatars depicting people of different ethnicities, nationalities, religions, ages, and occupations.'
                        }
                        brand={'Atomic Lotus, LLC'}
                        url={'https://diversityavatars.com/buy-now'}
                        price={'99'}
                />
                <SchemaAggregateOffer
                        name={'Diversity Avatars'}
                        price={'99'}
                        image={'https://diversityavatars.com/assets/images/3-faces.png'}
                        description={
                            'Diversity Avatars Essential Pack has 250 high-quality avatars depicting people of different ethnicities, nationalities, religions, ages, and occupations.'
                        }
                        brand={'Atomic Lotus, LLC'}
                        url={'https://diversityavatars.com/buy-now'}
                        lowPrice={'0'}
                        highPrice={'99'}
                        offerCount={'3'}
                />
            </React.Fragment>
        )
    }
}

export default Schema
