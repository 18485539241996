import React, {Component} from 'react';


class Logo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            secondaryColor : '#aaa',
            primaryColor   : '#fb3f2e'
        }

        this.onMouseOver = this.onMouseOver.bind(this)
        this.onMouseOut  = this.onMouseOut.bind(this)
    }

    onMouseOver() {
        this.setState({
            secondaryColor : '#fff'
        })
    }

    onMouseOut() {
        this.setState({
            secondaryColor : '#aaa'
        })
    }

    render() {
        return(
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="logo-svg"
                x="0" y="0"
                width={'210'}
                height={'36'}
                viewBox="0 0 210 36"
                onMouseOver={this.onMouseOver}
                onFocus={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                onBlur={this.onMouseOut}
            >
                <path
                    fill={this.state.secondaryColor}
                    d="M44.326 11.548l3.538 8.866 3.587-8.866h2.113l-5.742 13.754-5.61-13.754h2.114zM65.608 13.362H60.48v3.074h4.979v1.815H60.48v4.275h5.128v1.814h-7.059V11.548h7.059v1.814zM80.991 12.235v2.287c-1.116-.933-2.271-1.4-3.464-1.4-1.315 0-2.422.472-3.322 1.417-.906.939-1.359 2.088-1.359 3.447 0 1.342.453 2.475 1.359 3.397.906.923 2.016 1.383 3.331 1.383.679 0 1.256-.11 1.731-.331.265-.111.54-.26.824-.448.284-.187.584-.414.899-.679v2.328c-1.105.624-2.265.936-3.48.936-1.828 0-3.388-.638-4.681-1.914-1.287-1.287-1.93-2.839-1.93-4.656 0-1.629.538-3.082 1.615-4.358 1.326-1.563 3.041-2.345 5.146-2.345 1.149 0 2.259.312 3.331.936zM90.35 13.362v10.979h-1.93V13.362h-2.941v-1.814h7.805v1.814H90.35zM97.357 17.886c0-1.801.66-3.347 1.98-4.64 1.314-1.292 2.894-1.939 4.739-1.939 1.823 0 3.386.652 4.69 1.955 1.309 1.304 1.963 2.87 1.963 4.698 0 1.84-.657 3.4-1.972 4.682-1.32 1.287-2.914 1.93-4.781 1.93-1.652 0-3.134-.572-4.449-1.715-1.446-1.264-2.17-2.921-2.17-4.971zm1.947.025c0 1.414.475 2.576 1.425 3.488.945.911 2.035 1.367 3.273 1.367 1.342 0 2.474-.464 3.397-1.392.922-.939 1.383-2.082 1.383-3.43 0-1.365-.456-2.508-1.367-3.43-.907-.928-2.028-1.392-3.364-1.392-1.331 0-2.455.464-3.372 1.392-.917.917-1.375 2.049-1.375 3.397zM120.884 18.889l3.961 5.452h-2.362l-3.653-5.236h-.348v5.236h-1.931V11.548h2.262c1.691 0 2.911.318 3.662.953.828.707 1.243 1.64 1.243 2.8 0 .906-.26 1.685-.779 2.337-.52.652-1.204 1.069-2.055 1.251zm-2.402-1.467h.613c1.828 0 2.742-.699 2.742-2.096 0-1.309-.889-1.964-2.668-1.964h-.688v4.06h.001z">
                </path>
                <path
                    fill={this.state.secondaryColor}
                    d="M20.251 7.41c-6.113 0-11.168 4.35-12.931 10.58 1.763 5.995 6.818 10.58 12.931 10.58s11.05-4.585 12.931-10.58c-1.881-6.231-6.936-10.58-12.931-10.58zm0 19.396c-4.937 0-9.287-3.527-11.05-8.817 1.646-5.29 6.113-8.817 11.05-8.817 4.82 0 9.287 3.527 11.05 8.817-1.881 5.408-6.23 8.817-11.05 8.817z">
                </path>
                <path
                    fill={this.state.secondaryColor}
                    d="M20.251 13.653a4.412 4.412 0 0 0-4.408 4.408 4.413 4.413 0 0 0 4.408 4.408 4.414 4.414 0 0 0 4.408-4.408 4.413 4.413 0 0 0-4.408-4.408zm0 7.053a2.648 2.648 0 0 1-2.645-2.645 2.647 2.647 0 0 1 2.645-2.645 2.648 2.648 0 0 1 2.645 2.645 2.65 2.65 0 0 1-2.645 2.645z">
                </path>
                <path
                    fill={this.state.primaryColor}
                    d="M132.091 11.548v12.793h-1.931V11.548h1.931zM148.013 12.235v2.287c-1.116-.933-2.271-1.4-3.464-1.4-1.315 0-2.422.472-3.322 1.417-.906.939-1.359 2.088-1.359 3.447 0 1.342.453 2.475 1.359 3.397.906.923 2.016 1.383 3.331 1.383.679 0 1.256-.11 1.731-.331.265-.111.54-.26.824-.448.284-.187.585-.414.899-.679v2.328c-1.105.624-2.265.936-3.48.936-1.828 0-3.388-.638-4.681-1.914-1.287-1.287-1.93-2.839-1.93-4.656 0-1.629.538-3.082 1.615-4.358 1.326-1.563 3.041-2.345 5.146-2.345 1.148 0 2.259.312 3.331.936zM153.122 17.886c0-1.801.66-3.347 1.98-4.64 1.314-1.292 2.894-1.939 4.739-1.939 1.823 0 3.386.652 4.69 1.955 1.309 1.304 1.963 2.87 1.963 4.698 0 1.84-.657 3.4-1.972 4.682-1.32 1.287-2.914 1.93-4.781 1.93-1.652 0-3.134-.572-4.449-1.715-1.447-1.264-2.17-2.921-2.17-4.971zm1.947.025c0 1.414.475 2.576 1.425 3.488.945.911 2.035 1.367 3.273 1.367 1.342 0 2.474-.464 3.397-1.392.922-.939 1.383-2.082 1.383-3.43 0-1.365-.456-2.508-1.367-3.43-.907-.928-2.028-1.392-3.364-1.392-1.331 0-2.455.464-3.372 1.392-.917.917-1.375 2.049-1.375 3.397zM172.315 24.341V10.678l9.33 9.76v-8.89h1.93V25.12l-9.329-9.736v8.957h-1.931zM197.268 13.454l-1.566.928c-.293-.508-.572-.84-.837-.994-.276-.177-.632-.265-1.069-.265-.535 0-.98.152-1.334.456a1.41 1.41 0 0 0-.53 1.127c0 .624.464 1.127 1.392 1.508l1.276.522c1.038.42 1.798.932 2.278 1.537.481.605.721 1.347.721 2.225 0 1.177-.392 2.149-1.176 2.917-.79.773-1.77 1.16-2.941 1.16-1.111 0-2.028-.329-2.751-.986-.713-.657-1.157-1.582-1.334-2.775l1.956-.431c.088.751.243 1.271.464 1.558.397.552.977.828 1.74.828.602 0 1.102-.202 1.499-.605s.597-.914.597-1.533c0-.248-.035-.476-.104-.683s-.177-.398-.323-.572-.336-.337-.568-.489-.508-.297-.828-.435l-1.235-.514c-1.751-.74-2.626-1.823-2.626-3.248 0-.961.367-1.765 1.102-2.411.734-.652 1.648-.978 2.742-.978 1.475-.002 2.626.716 3.455 2.153z">
                </path>
            </svg>
        )
    }
}

export default Logo
