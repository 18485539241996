import React, {Component} from "react"
import {uuid} from "uuidv4";

/**
 * Section spacer.
 */
class SchemaProduct extends Component {
    render() {
        const schemaJson = JSON.stringify({
            "@context"    : "https://schema.org/",
            "@type"       : "Product",
            "name"        : this.props.name,
            "image"       : this.props.image,
            "description" : this.props.description,
            "brand"       : this.props.brand,
            "sku"         : this.props.sku,
            "offers"      : {
                "@type"         : "Offer",
                "url"           : this.props.url,
                "priceCurrency" : "USD",
                "price"         : this.props.price
            }
        });
        return (
            <script
                key={uuid()}
                type='application/ld+json'
                dangerouslySetInnerHTML={{ __html: schemaJson }}
            />
        )
    }
}

export default SchemaProduct
