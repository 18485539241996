import React from 'react';
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"


const Meta = () => {

    const { site } = useStaticQuery(
        graphql`
              query {
                site {
                  siteMetadata {
                    title
                    description
                    author
                    fbAppId
                    twitterHandle
                    lang
                    url
                    image
                  }
                }
              }
            `
    )

    return (
        <Helmet
            htmlAttributes={{
                lang : site.siteMetadata.lang,
                itemscope : '',
                itemtype  : 'product'
            }}
            // bodyAttributes={{
            //     class: [].join(' ')
            // }}
            title={site.siteMetadata.title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    property : `fb:app_id`,
                    content : site.siteMetadata.fbAppId
                },
                {
                    name : `twitter:image:src`,
                    content : site.siteMetadata.image
                },
                {
                    name : `twitter:image:alt`,
                    content : site.siteMetadata.title
                },
                {
                    name : `twitter:site`,
                    content : site.siteMetadata.twitterHandle
                },
                {
                    itemprop: `name`,
                    content: site.siteMetadata.title,
                },
                {
                    itemprop: `description`,
                    content: site.siteMetadata.metaDescription,
                },
                {
                    itemprop: `image`,
                    content: site.siteMetadata.image,
                },
                {
                    name: `description`,
                    content: site.siteMetadata.metaDescription,
                },
                {
                    property: `og:title`,
                    content: site.siteMetadata.title,
                },
                {
                    property: `og:description`,
                    content: site.siteMetadata.metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:url`,
                    content: site.siteMetadata.url,
                },
                {
                    name : `og:image`,
                    content : site.siteMetadata.image
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: site.siteMetadata.title,
                },
                {
                    name: `twitter:description`,
                    content: site.siteMetadata.metaDescription,
                },
            ]}
        />
    )
}

export default Meta;
