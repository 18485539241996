import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import footerLinks from "../../data/footer-links.json";
import Subscribe from "../Subscribe";

class Footer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            name : 'Atomic Lotus, LLC',
            date : new Date().getFullYear(),
            menuItems : footerLinks
        }
    }


    render() {
        return (
            <React.Fragment>
                <Subscribe/>
                <footer className="page-footer bg-white p-4 mb-2 mt-4">
                    <Container className="text-center text-md-left">
                        <Row>
                            <Col sm={12}>
                                <Nav>
                                    {this.state.menuItems.map((menuItem, i) => {
                                        return (
                                            <Nav.Link key={'menuItem-' + i} href={menuItem.link}>
                                                {menuItem.text}
                                            </Nav.Link>
                                        )
                                    })}
                                    <Nav.Link key={'menuItem-mastadon-aws'} rel="me" href={'https://awscommunity.social/@iconify'}>
                                        awscommunity.social
                                    </Nav.Link>
                                    <Nav.Link key={'menuItem-mastadon-world'} rel="me" href={'https://mastodon.world/@iconify'}>
                                        mastodon.world
                                    </Nav.Link>
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                    <div className="footer-copyright text-center py-3">
                        <Container fluid>
                            &copy; {this.state.date} {this.state.name}
                        </Container>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}

export default Footer;
