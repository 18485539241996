import MenuItems from "../data/menu-items.json";

const _PageLinks = {};
MenuItems.map((menuItem) => {
    if (! menuItem.children) {
        _PageLinks[menuItem.text.replace(/\s/g, '').match(/[A-Za-z]+/)] = menuItem.link;
    }
    else {
        menuItem.children.map((childItem) => {
            _PageLinks[childItem.text.replace(/\s/g, '').match(/[A-Za-z]+/)] = childItem.link;
            return childItem;
        })
    }
    return menuItem;
})

export const PageLinks = _PageLinks;

export const IconfinderSearchLink = 'https://www.iconfinder.com/search/?q=iconify:+';
