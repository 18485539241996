import React, { Component } from "react"
import {uuid} from "uuidv4";

/**
 * Section spacer.
 */
class SchemaPerson extends Component {
    render() {
        const schemaJson = JSON.stringify({
            "@context"   : "http://schema.org",
            "@type"      : "Person",
            "familyName" : `"${this.props.familyName}"`,
            "givenName"  : `"${this.props.givenName}"`,
            "worksFor"   : `"${this.props.worksFor}"`,
            "jobTitle"   : `"${this.props.jobTitle}"`,
            "gender"     : `"${this.props.gender}"`,
            "sameAs"     : `"${this.props.sameAs}"`
        });

        return (
            <script
                key={uuid()}
                type='application/ld+json'
                dangerouslySetInnerHTML={{ __html: schemaJson }}
            />
        )
    }
}

export default SchemaPerson
