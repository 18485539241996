import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import React from "react";
import { uuid } from 'uuidv4';
import {useForm} from "react-hook-form";


const Subscribe = (props) => {

    const formId = `form-${uuid()}`;
    const { register, handleSubmit, watch, errors } = useForm();

    const onSubmit = (data, e) => {
        e.target.submit();
    }

    return (
        <Container className={'subscribe-form'}>
            <Row className={'pl-4'}>
                <Col>
                    <form
                        id={'subscribe-form'}
                        name={'vectoricons-subscribe'}
                        data-netlify="true"
                        method={'POST'}
                        netlify-honeypot={'bot-field'}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="vectoricons-subscribe" />
                        <Row>
                            <Col xs={false} md={1} lg={2} xl={4} />
                            <Col xs={8} lg={6} xl={3}>
                                <ul className="formkit-alert formkit-alert-error"
                                    data-element="errors"
                                    data-group="alert">
                                </ul>
                            </Col>
                            <Col xs={false} md={1} lg={2} xl={4} />
                        </Row>
                        <Row data-element="fields"
                             data-stacked="false"
                             className="seva-fields formkit-fields"
                        >
                            <Col xs={false} md={1} lg={2} xl={2} />
                            <Col xs={8} lg={6} xl={6}>
                                <div className="formkit-field">
                                    <label htmlFor={formId}>
                                        <span style={{"display": "none"}}>Email Field</span>
                                    </label>
                                    <input
                                        id={formId}
                                        aria-labelledby={formId}
                                        className="formkit-input"
                                        name="email_address"
                                        placeholder="Your email address"
                                        required="" type="email"
                                        style={{
                                            "color": "#888",
                                            "borderRadius": "4px",
                                            "border" : "1px solid #ccc",
                                            "fontWeight": 400,
                                            "width" : "100%",
                                            "padding" : "8px"
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs={1} lg={3} xl={3}>
                                <button
                                    data-element="submit"
                                    className={'pill-button'}
                                    variant={'dark'}
                                    aria-label={'Subscribe'}
                                    style={{
                                        "color": "rgb(255, 255, 255)",
                                        "backgroundColor": "#000",
                                        "borderRadius": "4px",
                                        "border" : "none",
                                        "fontWeight": 400
                                    }}
                                >
                                    Subscribe
                                </button>
                            </Col>
                            {/*<Col xs={1} lg={2} xl={4} />*/}
                        </Row>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}

export default Subscribe;
