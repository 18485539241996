// React & Gatsby
import React from "react"
import { Helmet } from "react-helmet"
import Jumbotron from "react-bootstrap/Jumbotron";

// Custom
import Meta from "../Meta";
import Footer from "../Footer";
import Schema from "../Schema";
import MainMenu from "../MainMenu";
import menuItems from "../../data/menu-items.json";

// Data & Styles
import "../../assets/scss/App.scss"


export default function Page(props) {
    const { children } = props;
    return (
        <>
            <div className={props.className}>
                <Helmet></Helmet>
                <Meta/>
                <Jumbotron className={'m-0 p-0 home-page-hero'}>
                    <MainMenu menuItems={menuItems}/>
                    {props.hero}
                </Jumbotron>
                {children}
                <Footer />
                <Schema/>
            </div>
        </>
    )
}
